<template>
  <section class="mb-3 mb-md-4">
    <h3>
      {{ item.attributes.titre }}
      <b-badge variant="warning" v-if="!item.attributes.publishedAt">
        Brouillon
      </b-badge>
    </h3>
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <b-row>
      <b-col class="mb-3 mr-0 mb-md-0 mr-md-3" cols="12" md="auto">
        <b-link v-if="catalogueHref" target="_blank" :href="catalogueHref">
          <b-img
            class="couverture"
            fluid
            :src="couvertureSrc"
            :alt="couvertureAlt"
          ></b-img>
        </b-link>
        <b-img
          v-else
          class="couverture"
          fluid
          :src="couvertureSrc"
          :alt="couvertureAlt"
        ></b-img>
      </b-col>
      <b-col>
        <div>
          <StrapiMarkdownComponent
            :item="item.attributes.description"
          ></StrapiMarkdownComponent>
        </div>
        <div class="mt-3">
          <b-link v-if="catalogueHref" target="_blank" :href="catalogueHref">
            <b-button pill variant="primary">
              T&eacute;l&eacute;charger le catalogue
            </b-button>
          </b-link>
        </div>
        <div v-for="bon in bons" :key="bon.id" class="mt-1">
          <b-link target="_blank" :href="bonHref(bon)">
            <b-button pill variant="outline-primary">
              {{ bon.attributes.caption }}
            </b-button>
          </b-link>
        </div>
        <div v-if="$store.getters['auth/hasAccesApproGestion']" class="mt-3">
          <b-link :to="{ name: 'listes-produits', params: { id: item.id } }">
            <b-button pill variant="outline-warning">
              <b-icon-tools></b-icon-tools>
              Modifier la page de commande
            </b-button>
          </b-link>
          <b-link :to="{ name: 'commande-appro', params: { id: item.id } }">
            <b-button pill variant="warning">
              <b-icon-cart3></b-icon-cart3>
              Visualiser la page de commande
            </b-button>
          </b-link>
          <br />
          <b-button
            v-if="commandeOuverte"
            pill
            variant="warning"
            @click="fermerCommande"
          >
            <b-icon-lock></b-icon-lock>
            Fermer le catalogue &agrave; la commande
          </b-button>
          <b-button
            v-else
            pill
            variant="outline-warning"
            @click="ouvrirCommande"
          >
            <b-icon-unlock-fill></b-icon-unlock-fill>
            Ouvrir le catalogue &agrave; la commande
          </b-button>
        </div>
        <div v-else-if="$store.getters['auth/hasAccesApproCommande'] && commandeOuverte" class="mt-3">
          <b-link :to="{ name: 'commande-appro', params: { id: item.id } }">
            <b-button pill variant="primary">
              <b-icon-cart3></b-icon-cart3>
              Commander en ligne
            </b-button>
          </b-link>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import StrapiMarkdownComponent from "../../components/strapi/StrapiMarkdownComponent.vue";
import ApproService from "../../services/appro.service";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "StrapiCatalogueComponent",
  props: ["item"],
  components: { ErrorComponent, StrapiMarkdownComponent },
  data: () => ({
    error: null,
    catalogue: null,
  }),
  created() {
    ApproService.getCatalogue(this.item.id)
      .then((response) => (this.catalogue = response.data))
      .catch((error) => (this.error = UtilsService.handleError(error)));
  },
  computed: {
    couvertureSrc() {
      return StrapiService.getImageUrl(
        this.item.attributes.couverture,
        "small"
      );
    },
    couvertureAlt() {
      return (
        this.item.attributes.couverture?.data?.attributes.alternativeText || ""
      );
    },
    catalogueHref() {
      return StrapiService.getAssetUrl(
        this.item.attributes.catalogue?.data?.attributes.url
      );
    },
    bons() {
      return this.item.attributes.bons?.data || [];
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    commandeOuverte() {
      return this.catalogue?.ouvert === true;
    },
  },
  methods: {
    bonHref(bon) {
      return StrapiService.getAssetUrl(bon.attributes.url);
    },
    ouvrirCommande() {
      this.error = null;
      ApproService.ouvrirCommande(this.item.id)
        .then((response) => (this.catalogue = response.data))
        .catch((error) => (this.error = UtilsService.handleError(error)));
    },
    fermerCommande() {
      this.error = null;
      ApproService.fermerCommande(this.item.id)
        .then((response) => (this.catalogue = response.data))
        .catch((error) => (this.error = UtilsService.handleError(error)));
    },
  },
};
</script>
