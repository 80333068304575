<template>
  <section class="mb-3 mb-md-4">
    <h3>{{ titre }}</h3>
    <b-row>
      <b-col class="mb-3 mr-0 mb-md-0 mr-md-3" cols="12" md="auto">
        <b-img
          class="couverture"
          fluid
          :src="couvertureSrc"
          :alt="couvertureAlt"
        ></b-img>
      </b-col>
      <b-col>
        <b-row align-h="between">
          <b-col cols="12" xl="auto">
            <StrapiMarkdownComponent
              :item="item.attributes.description"
            ></StrapiMarkdownComponent>
          </b-col>
          <b-col
            v-if="item.attributes.fichiers"
            cols="auto"
            class="mt-3 mt-xl-0"
          >
            <StrapiFichiersComponent :item="item.attributes.fichiers">
              <template #titre>Documents disponibles</template>
            </StrapiFichiersComponent>
          </b-col>
        </b-row>
        <template v-if="$store.getters['auth/hasAccesAdministratifAgGestion']">
          <b-link :to="{ name: 'assemblee-generale', params: { id: item.id } }">
            <b-button pill variant="warning">
              Voir les r&eacute;ponses de tous les adh&eacute;rents
            </b-button>
          </b-link>
        </template>
        <template v-if="$store.getters['auth/hasAccesAdministratifAgAdherent']">
          <b-row v-if="formulaireOuvert" class="mt-5">
            <b-col cols="12">
              <h2>R&eacute;pondre &agrave; mon invitation</h2>
              <ErrorComponent
                :error="error"
                @hide="error = null"
              ></ErrorComponent>
            </b-col>
            <b-col>
              <div>
                <strong>
                  Vous serez pr&eacute;sent et voterez en votre nom ?
                </strong>
                Vous pouvez r&eacute;cup&eacute;rer, remplir et d&eacute;poser
                votre coupon-r&eacute;ponse ci-dessous :
              </div>
              <div class="mt-2">
                <b-link target="_blank" :href="couponReponseHref">
                  <b-button pill variant="outline-primary">
                    T&eacute;l&eacute;charger le coupon-r&eacute;ponse &agrave;
                    remplir
                  </b-button>
                </b-link>
                &nbsp;
                <b-button pill variant="primary" @click="nouveauCouponReponse">
                  D&eacute;poser mon coupon-r&eacute;ponse
                </b-button>
              </div>
              <div class="mt-3">
                <strong>Vous ne pouvez pas &ecirc;tre pr&eacute;sent ?</strong>
                Vous pouvez r&eacute;cup&eacute;rer, remplir,
                <strong>signer</strong> et d&eacute;poser votre bon pour pouvoir
                ci-dessous :
              </div>
              <div class="mt-2">
                <b-link target="_blank" :href="bonPouvoirHref">
                  <b-button pill variant="outline-primary">
                    T&eacute;l&eacute;charger le bon pour pouvoir &agrave;
                    remplir
                  </b-button>
                </b-link>
                &nbsp;
                <b-button pill variant="primary" @click="nouveauBonPouvoir">
                  D&eacute;poser mon bon pour pouvoir
                </b-button>
              </div>
            </b-col>
          </b-row>
          <template v-if="reponses && reponses.length > 0">
            <b-row class="mt-5">
              <b-col>
                <h2>Documents d&eacute;j&agrave; d&eacute;pos&eacute;s</h2>
              </b-col>
            </b-row>
            <b-row v-for="reponse in reponses" :key="reponse.idReponse">
              <b-col cols="6" xl="3">
                <DownloadComponent
                  :typeDocument="typeDocument"
                  :targetId="reponse.idReponse"
                >
                  <template #label>
                    &nbsp;{{ reponse.libelleTypeDocument }}
                  </template>
                </DownloadComponent>
              </b-col>
              <b-col cols="6" xl="3">
                <DateTimeComponent :value="reponse.dateHeureDepot">
                </DateTimeComponent>
              </b-col>
              <b-col cols="12" xl="6" class="mb-3 mb-xl-0">
                {{ reponse.commentaire }}
              </b-col>
            </b-row>
          </template>
        </template>
      </b-col>
    </b-row>
    <FileUploadDialog ref="fileUploadDialog" :title="dialog.titre">
      <template #bottom>
        <em>
          Votre {{ dialog.document }} sera transmis automatiquement au service
          administratif de votre coop&eacute;rative.
        </em>
      </template>
    </FileUploadDialog>
  </section>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
import StrapiFichiersComponent from "./StrapiFichiersComponent.vue";
import DownloadComponent from "../controls/DownloadComponent.vue";
import DateTimeComponent from "../DateTimeComponent.vue";
import FileUploadDialog from "../dialogs/FileUploadDialog.vue";
import ExploitationService from "../../services/exploitation.service";
import StrapiService from "../../services/strapi.service";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "StrapiAssembleeGeneraleComponent",
  props: ["item"],
  components: {
    ErrorComponent,
    StrapiMarkdownComponent,
    StrapiFichiersComponent,
    DownloadComponent,
    DateTimeComponent,
    FileUploadDialog,
  },
  data() {
    return {
      typeDocument: this.$store.state.expl.typesDocument.REPONSE_AG,
      dialog: {
        titre: null,
        document: null,
        postFunction: null,
      },
      error: null,
      reponses: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    titre() {
      return (
        this.item.attributes.titre ||
        `Assemblée générale ${this.item.attributes.campagne}`
      );
    },
    couvertureSrc() {
      return StrapiService.getImageUrl(
        this.item.attributes.couverture,
        "small"
      );
    },
    couvertureAlt() {
      return (
        this.item.attributes.couverture?.data?.attributes.alternativeText || ""
      );
    },
    formulaireOuvert() {
      let now = new Date().toISOString().slice(0, 10);
      return (
        this.item.attributes.ouvertureFormulaire.localeCompare(now) <= 0 &&
        now.localeCompare(this.item.attributes.fermetureFormulaire) <= 0
      );
    },
    bonPouvoirHref() {
      return StrapiService.getAssetUrl(
        this.item.attributes.bonPouvoir?.data?.attributes.url
      );
    },
    couponReponseHref() {
      return StrapiService.getAssetUrl(
        this.item.attributes.couponReponse?.data?.attributes.url
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let response = await ExploitationService.getReponsesAgByTiers(
        this.item.id,
        this.exploitationCourante.codeTiers
      );
      this.reponses = response.data
        .map((r) => {
          if (r.typeDocument === "COUPON_REPONSE") {
            r.libelleTypeDocument = "Coupon-réponse";
          } else if (r.typeDocument === "BON_POUVOIR") {
            r.libelleTypeDocument = "Bon pour pouvoir";
          } else {
            r.libelleTypeDocument = r.typeDocument.replaceAll("_", " ");
          }
          return r;
        })
        .sort(
          UtilsService.sortByStringProperty(
            "dateHeureDepot",
            SortDirection.DESC
          )
        );
    },
    nouveauCouponReponse() {
      this.dialog.titre = "Nouveau coupon-réponse";
      this.dialog.document = "coupon-réponse";
      this.dialog.postFunction = ExploitationService.createCouponReponse;
      this.nouveauDocument();
    },
    nouveauBonPouvoir() {
      this.dialog.titre = "Nouveau pouvoir";
      this.dialog.document = "bon pour pouvoir";
      this.dialog.postFunction = ExploitationService.createBonPouvoir;
      this.nouveauDocument();
    },
    async nouveauDocument() {
      let upload = await this.$refs.fileUploadDialog.show();
      if (!upload?.fichier) {
        return;
      }

      let reponse = {
        idAssemblee: this.item.id,
        codeTiers: this.exploitationCourante?.codeTiers,
        commentaire: upload.commentaire,
      };

      let formData = new FormData();
      formData.append(
        "reponse",
        new Blob([JSON.stringify(reponse)], { type: "application/json" })
      );
      formData.append("fichier", upload.fichier, upload.fichier?.name);

      this.error = null;
      try {
        this.loading = true;
        await this.dialog.postFunction(formData);
        this.load();
      } catch (error) {
        this.error = UtilsService.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
